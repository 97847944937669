<template>
  <layout width="540">
    <template slot="header">
      Set coordinates sources
    </template>

    <template>
      <v-form>
        <template v-for="(source, index) in state.sources">
          <form-subheader
            :key="`header_${index}`"
            :title="`Coordinate source ${index + 1}`"
          />
          <div :key="`source_${index}`">
            <v-select
              v-model="source.sourceId"
              label="Source"
              :items="sources"
              item-value="id"
              item-text="name"
              clearable
            />
            <v-select
              v-model="source.propertyId"
              label="Property"
              item-value="id"
              item-text="description"
              :items="properties(source.sourceId)"
              clearable
            />
            <v-select
              v-model="source.geotagId"
              label="Geotag"
              item-value="id"
              item-text="name"
              :items="geotags"
              clearable
            />
          </div>
        </template>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onBeforeMount } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { linkedObjectsService } from '@/modules/linked-objects/api';
import { objectService } from '../api';
import { objectPropertyService } from '@/modules/common/api';
import { useGeotags } from '@/modules/geotags/compositions/geotags';

export default {
  name: 'ObjectCoordinateSourcesEdit',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      sources: []
    });

    const sources = ref([]);
    const object = ref({});

    const { list: geotags } = useGeotags();

    const handleSubmit = async () => {
      await objectPropertyService.update(object.value.positionSources.id, {
        value: state.value.sources.filter(
          source => source.sourceId && source.propertyId
        )
      });
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    const properties = sourceId => {
      const source = sources.value.find(source => source.id === sourceId);
      const schemaProperties = source?.schema?.schemaProperties || [];
      const getDescription = propertyKey =>
        schemaProperties.find(property => property.property === propertyKey)
          ?.description;

      return source?.objectProperties.map(property => ({
        ...property,
        description: getDescription(property.property) || property.property
      }));
    };

    const defaultSource = () => ({
      sourceId: '',
      propertyId: '',
      geotagId: ''
    });

    onBeforeMount(async () => {
      sources.value = await linkedObjectsService.fetch(props.objectId);
      sources.value = sources.value.filter(
        device =>
          !!device.objectProperties.find(
            property => property.groupName === 'Spatial'
          )
      );

      object.value = await objectService.fetch(props.objectId);

      state.value.sources = Array(3)
        .fill()
        .map(
          (_, index) =>
            ({ ...object?.value?.positionSources?.value[index] } ||
            defaultSource())
        );
    });

    return {
      state,
      sources,
      loading,
      submit,
      properties,
      geotags
    };
  }
};
</script>
